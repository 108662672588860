import React from 'react';
import {useNavigate} from "react-router-dom";

const Obrazek = () => {

    const navigate = useNavigate();

    return (
        <div>
            {/* Kontent nad obrázkem */}
            <div className="pt-44 md:pt-48 space-y-3.5 text-center text-[#A4A4A4] w-full bg-black mb-8">
                <div>
                    <div>
                        <h1 className="text-[32px] md:text-[48px] font-semibold mb-2 md:mb-3">Teď Nebo Nikdy</h1>
                        <p className="text-[16px] md:text-[21px] mb-6" style={{lineHeight: '1.2'}}>Nejžhavější drop roku 2024</p>
                        <p className="text-[16px] md:text-[24px] font-semibold mb-6" style={{lineHeight: '1.2'}}>iPhone 15 Pro za 14 500,-</p>
                        <button
                            //onclick navigate to /product/0
                            onClick={() => navigate("/products/iphone")}
                            className="px-5 md:px-7 py-2 md:py-3 font-bold bg-blue-600 text-white text-[14px] md:text-[17px] rounded-full hover:bg-blue-700 transition">
                            Koupit
                        </button>
                    </div>
                    <div className="w-full flex justify-center">
                        <img className="w-full md:w-[50%]" src={"https://api.appledrops.eu/cdn/preview1000.png"} alt="Logo"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Obrazek;
