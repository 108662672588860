import React, { createContext, useState, useEffect } from "react";

// Create a context
export const ProductContext = createContext();

// Contexts provider component
export function ProductProvider({ children }) {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://api.appledrops.eu/products/0");
                const data = await response.json();
                setProducts(data); // Load all products
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchData();
    }, []); // This effect runs only once when the component mounts, so data is only fetched once.

// Filter products by collection handle and sort by product.order
    const filterProductsByCollection = (collectionHandle) => {
        return products
            .filter((product) =>
                product.collections.some((collection) => collection.handle === collectionHandle)
            )
            .slice() // Create a shallow copy to avoid mutating the original array
            .sort((a, b) => a.order - b.order); // Sort by product.order
    };

// Filter products by tag handle and sort by product.order
    const filterProductsByTag = (tagHandle) => {
        return products
            .filter((product) =>
                product.tags.some((tag) => tag.handle === tagHandle)
            )
            .slice() // Create a shallow copy to avoid mutating the original array
            .sort((a, b) => a.order - b.order); // Sort by product.order
    };

    const filterProductsByTitle = (searchTerm) => {
        const lowercasedSearchTerm = searchTerm.toLowerCase();
        return products.filter((product) =>
                product.title.toLowerCase().includes(lowercasedSearchTerm)
            );
    };

    return (
        <ProductContext.Provider value={{
            products, // All loaded products
            filterProductsByCollection, // Function to filter by collection
            filterProductsByTag, // Function to filter by tag
            filterProductsByTitle
        }}>
            {children}
        </ProductContext.Provider>
    );
}
