import React from 'react';
import BenefitsBar from "../BenefitsBar";
import {TbBrandAppleArcade, TbPackageImport, TbTruckDelivery} from "react-icons/tb";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const OrderTracking = ({desc}) => {
    return (

        <div className={"w-full"}>
            <div className="flex bg-t py-6 relative">
                {/* Step 1: Order */}
                <div className={"flex flex-col z-30 w-full h-48"}>
                    <div className="text-center text-white">
                        <div
                            className="border-2 border-black bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2">
                            <TbPackageImport className={"text-[23px]"}/>
                        </div>
                    </div>

                    <div className={"text-center"}>
                        <p className="text-[15px] font-semibold break-words">Objednávka přijata</p>
                        <p className="text-gray-400">14/11</p>
                    </div>
                </div>

                {/* Step 2: On the Way */}
                <div className={"flex flex-col z-40 w-full h-48"}>
                    <div className="text-center text-white">
                        <div
                            className="border-2 border-black bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2 pb-1">
                            <TbTruckDelivery className={"pt-1 md:pt-1.5 text-[27px]"}/>
                        </div>
                    </div>

                    <div className={"text-center"}>
                        <p className="text-[15px] font-semibold break-words">Objednávka je na cestě</p>
                        <p className="text-gray-400">15/11 - 17/11</p>
                    </div>
                </div>

                {/* Step 3: Delivery */}
                <div className={"flex flex-col z-40 w-full h-48"}>
                    <div className="text-center text-white">
                        <div
                            className="border-2 border-black bg-blue-600 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-2 pt-[0.5px]">
                            <TbBrandAppleArcade className={"text-[25px]"}/>
                        </div>
                    </div>
                    <div className={"text-center"}>
                        <p className="text-[15px] font-semibold">Doručení</p>
                        <p className="text-gray-400">19/11 - 21/11</p>
                    </div>
                </div>

                {/* Centered Green Line */}
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className=" -mt-[143px]  py-[2px] bg-blue-600 w-4/6"></div>
                </div>
            </div>

            <div className={desc.length > 0 ? "-mt-16" : "-mt-40"}>
                <div className="list-disc tracking-wider mb-24 ">
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                    >
                        {desc.replace(/\n/g, '  \n')}
                    </ReactMarkdown>
                </div>

                <BenefitsBar/>
            </div>
        </div>
    );
};

export default OrderTracking;
