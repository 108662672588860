import React from "react";
import { useNavigate } from "react-router-dom";
import Star from "../Reviews/Star";

const ProductCard = ({ product, averageRating, reviewsCount, shortReview }) => {
    const navigate = useNavigate();

    const handleProductClick = () => {
        // Navigate to the product's detail page
        navigate(`/products/${product.product_handle}`);
    };

    return (
        <div
            key={product.id}
            onClick={handleProductClick}
            className="cursor-pointer rounded-lg overflow-hidden shadow-lg hover:shadow-xl transform transition-all duration-300"
        >
            <div className="relative max-w-screen h-fit">
                <div className="w-full h-full">
                    <img
                        src={product.img}
                        alt={product.title}
                        className="w-full h-full object-cover rounded-md"
                    />
                </div>
                {/* Show the Sold Out badge if stock is 0 */}
                {product.stock === 0 && (
                    <span className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
            Sold Out
        </span>
                )}
            </div>


            <div className="pt-0 pb-4 px-4">
                <h3 className="text-lg font-semibold text-white">{product.title}</h3>

                {reviewsCount > 0 && (
                    <div className="inline-flex">
                        {/* Display stars based on average rating */}
                        <div className="flex items-center mt-2">
                            {[...Array(Math.round(averageRating))].map((_, index) => {
                                return <Star key={index} color={"text-blue-600"} size={4} />;
                            })}
                        </div>
                        {/* Show review count only if there are reviews */}
                        <p className="ml-1 text-sm text-gray-400 mt-2">{shortReview ? "(" + reviewsCount + ")" : "(" + reviewsCount + " reviews)"}</p>
                    </div>
                )}

                <p className="text-md font-medium text-gray-300 mt-2">{product.price} Kč</p>
            </div>
        </div>
    );
};

export default ProductCard;
