import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductPage from "../Components/Product/ProductPage";
import DescriptionBlockLeft from "../Components/Blocks/Product/DescriptionBlockLeft";
import DescriptionBlockRight from "../Components/Blocks/Product/DescriptionBlockRight";
import { ProductContext } from "../Contexts/ProductContext";

function ProductMain() {
    const { id: productHandle } = useParams();
    const { products } = useContext(ProductContext);

    const product = products.find((p) => p.product_handle === productHandle);

    if (product) {
        // Sort the product's images by their 'order' property
        product.images = product.images.slice().sort((a, b) => a.order - b.order);
    }


    useEffect(() => {
        console.log("Product Handle:", productHandle);
        console.log("Found Product:", product);

        // Scroll to the top of the page when navigating
        window.scrollTo(0, 0);
    }, [productHandle, product]);

    if (!product) {
        return <div className="text-white text-center mt-10">Loading...</div>;
    }

    return (
        <ProductPage
            key={productHandle} // Force re-render on handle change
            title={product.title}
            price={product.price}
            stock={product.stock}
            img={product.img}
            desc={product.desc ? product.desc : ""}
            images={product.images}
            rating={product.rating}
            variants={product.variants}
            description={product.description.map((desc, index) => {
                if (desc.type === "DescriptionBlockLeft") {
                    return (
                        <DescriptionBlockLeft
                            key={index}
                            title={desc.title}
                            description={<p>{desc.description}</p>}
                            img={desc.img}
                        />
                    );
                } else if (desc.type === "DescriptionBlockRight") {
                    return (
                        <DescriptionBlockRight
                            key={index}
                            title={desc.title}
                            description={<p>{desc.description}</p>}
                            img={desc.img}
                        />
                    );
                }
                return null;
            })}
            handle={productHandle}
        />
    );
}

export default ProductMain;
