import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiSearch, FiShoppingBag } from "react-icons/fi";
import logo from "../imgs/logo.png";
import { useCart } from "../Contexts/CartContext"; // Use CartContext
import CartDrawer from "./Cart/CartDrawer";
import SearchOverlay from "./SearchOverlay";

const Navbar = () => {
    const { isDrawerOpen, closeDrawer, cartItems } = useCart(); // Access cart items from context
    const [isOpen, setIsOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false); // State to manage search overlay visibility
    const [logoSize, setLogoSize] = useState("h-[70px] md:h-[85px]"); // Default size
    const [menuSize, setMenuSize] = useState("h-[130px]"); // Default size
    const [drawerTop, setDrawerTop] = useState("top-[130px]"); // Drawer top position based on menu size
    const [cartTop, setCartTop] = useState("bottom-12"); // Drawer top position based on menu size
    const navigate = useNavigate();
    const location = useLocation(); // Use useLocation to get current path

    const currentPath = location.pathname; // Track the current path

    // Effect to handle scroll event
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                // If scrolled down 50px, reduce logo size
                setLogoSize("h-[65px]");
                setMenuSize("h-[110px]");
                setDrawerTop("top-[110px]"); // Adjust drawer position as navbar shrinks
                setCartTop("bottom-10");
            } else {
                // If at the top, reset to original size
                setLogoSize("h-[70px] md:h-[85px]");
                setMenuSize("h-[130px]");
                setDrawerTop("top-[130px]"); // Adjust drawer position as navbar shrinks
                setCartTop("bottom-12");
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Calculate the number of items in the cart
    const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);

    // Determine the cart badge content
    const badgeContent = cartItemCount > 9 ? "9+" : cartItemCount > 0 ? cartItemCount : null;

    return (
        <nav
            className="bg-white dark:bg-black fixed w-full z-50 top-0 left-0 border-b border-gray-200 dark:border-[#262626]">

            {/* PC MENU */}
            <div className="flex items-center w-full md:w-3/5 mx-auto">
                <div
                    className={`transition-all duration-300 flex items-center justify-between p-2 ${menuSize} relative w-full`}
                >
                    {/* Left Section: Hamburger Menu (Only on mobile) */}
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="pr-8 bg-transparent md:hidden inline-flex items-center p-2 w-20 h-8 justify-center text-xs rounded-lg text-white"
                        aria-expanded={isOpen ? "true" : "false"}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 17 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1h15M1 7h15M1 13h15"
                            />
                        </svg>
                    </button>

                    {/* Middle Section: Logo (Always visible, centered) */}
                    <div
                        className="flex items-center justify-center flex-1 md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                        <button onClick={() => navigate("/")} className="flex w-full justify-center">
                            <img src={logo}
                                 className={`${logoSize} transition-all duration-300 p-1`} alt="Logo"/>
                        </button>
                    </div>

                    {/* Right Section: Search and Cart Icons (Only on mobile and desktop) */}
                    <div className="flex items-center p-2 w-20 h-8 space-x-4 md:hidden">
                        {/* Cart Badge */}
                        <FiShoppingBag onClick={() => navigate("/cart")}
                                       className="h-6 w-6 text-gray-900 dark:text-white"/>
                        {badgeContent && (
                            <div
                                className={"absolute transition-all duration-300 " + cartTop + " right-12 bg-blue-600 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center"}>
                                {badgeContent}
                            </div>
                        )}
                        <FiSearch
                            onClick={() => setIsSearchOpen(true)} // Open the search overlay
                            className="h-5 w-5 text-gray-900 dark:text-white"
                        />
                    </div>

                    {/* Links and Icons (Visible only on desktop) */}
                    <div
                        className="hidden md:grid grid-cols-1 md:grid-cols-3 items-center justify-between w-full gap-4">
                        {/* Navigation Buttons */}
                        <div className="flex justify-start flex-wrap md:col-span-1 space-x-4">
                            <button
                                onClick={() => navigate("/")}
                                className={`${
                                    currentPath === "/" ? "underline text-white" : "text-[#A4A4A4]"
                                } text-[14px] hover:text-white`}
                            >
                                Domů
                            </button>
                            <button
                                onClick={() => navigate("/ukoncene-nabidky")}
                                className={`${
                                    currentPath === "/ukoncene-nabidky" ? "underline text-white" : "text-[#A4A4A4]"
                                } text-[14px] hover:text-white`}
                            >
                                Ukončené nabídky
                            </button>
                            <button
                                onClick={() => navigate("/nejcastejsi-dotazy")}
                                className={`${
                                    currentPath === "/nejcastejsi-dotazy" ? "underline text-white" : "text-[#A4A4A4]"
                                } text-[14px] hover:text-white`}
                            >
                                FAQ
                            </button>
                            {/* Add more buttons if needed */}
                        </div>

                        {/* Logo */}
                        <div className="flex justify-center col-span-1">
                            <button onClick={() => navigate("/")} className="flex w-full justify-center">
                                <img
                                    src={logo}
                                    className={`${logoSize} transition-all duration-300 p-1`}
                                    alt="Logo"
                                />
                            </button>
                        </div>

                        {/* Cart and Search Icons */}
                        <div className="flex justify-end items-center space-x-6 md:col-span-1 relative">
                            {/* Cart Icon */}
                            <div className="relative flex items-center">
                                <FiShoppingBag
                                    onClick={() => navigate("/cart")}
                                    className="h-6 w-6 text-gray-900 dark:text-white"
                                />
                                {/* Cart Badge */}
                                {badgeContent && (
                                    <div
                                        className="absolute -top-2 -right-2 bg-blue-600 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                                    >
                                        {badgeContent}
                                    </div>
                                )}
                            </div>
                            {/* Search Icon */}
                            <FiSearch
                                onClick={() => setIsSearchOpen(true)} // Open the search overlay
                                className="h-6 w-6 text-gray-900 dark:text-white"
                            />
                        </div>

                    </div>


                    {/* Cart Drawer */}
                    {isDrawerOpen && <CartDrawer size={drawerTop} onClose={() => closeDrawer()}/>}
                </div>
            </div>
            {/* Search Overlay */}
            {isSearchOpen && <SearchOverlay isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)}/>}

            {/* Mobile Menu (visible only when hamburger is open) */}
            <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
                <ul className="flex flex-col p-2 font-medium rounded-lg dark:border-gray-700">
                    <li>
                        <button
                            onClick={() => {
                                navigate("/");
                                setIsOpen(false);
                            }}
                            className={`${
                                currentPath === "/" ? "underline" : ""
                            } block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent`}
                        >
                            Domů
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                navigate("/ukoncene-nabidky");
                                setIsOpen(false);
                            }}
                            className={`${
                                currentPath === "/ukoncene-nabidky" ? "underline" : ""
                            } block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent`}
                        >
                            Ukončené nabídky
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => {
                                navigate("/nejcastejsi-dotazy");
                                setIsOpen(false);
                            }}
                            className={`${
                                currentPath === "/nejcastejsi-dotazy" ? "underline" : ""
                            } block py-1 px-2 text-gray-900 rounded dark:text-white bg-transparent`}
                        >
                            FAQ
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;