import React, { useState } from "react";
import Star from "./Star";
import { FaCheckCircle } from "react-icons/fa";

function ReviewItem({ name, title, content, date, avatarUrl, img, rating, verified }) {
    // Format the date
    const formattedDate = new Date(date).toLocaleDateString("en-GB"); // 'en-GB' gives dd/mm/yy format

    // State to manage overlay visibility and selected image
    const [isOverlayOpen, setOverlayOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOverlayOpen(true);
    };

    const handleOverlayClose = () => {
        setOverlayOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="flex flex-col items-start mt-6 w-full">
            {/* Overlay for full-size image */}
            {isOverlayOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 flex items-center justify-center z-50"
                    onClick={handleOverlayClose}
                >
                    <div className="relative">
                        <button
                            className="absolute top-4 right-4 text-white text-2xl z-50"
                            onClick={handleOverlayClose}
                        >
                            ✕
                        </button>
                        <img
                            src={selectedImage}
                            alt="Full-size Review"
                            className="max-w-full max-h-screen object-contain"
                        />
                    </div>
                </div>
            )}

            <div className="flex items-center justify-between w-full">
                {/* Stars */}
                <div className="flex items-center">
                    {[...Array(rating)].map((_, i) => (
                        <Star key={i} color={"text-blue-600"} size={4} />
                    ))}
                </div>

                {/* Date aligned to the far right */}
                <span className="text-sm text-gray-500 dark:text-gray-400">{formattedDate}</span>
            </div>

            {/* Name and Avatar */}
            <div className="flex mt-2">
                <img
                    src={avatarUrl || "https://placehold.co/40"}
                    alt={`${name}'s avatar`}
                    className="w-10 h-10 mr-4"
                />
                <div className="flex items-center justify-between">
                    <span className="font-semibold text-white">{name}</span>
                    {verified && (
                        <span className="flex items-center justify-between ml-2 bg-blue-100 text-blue-600 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                            Verified <FaCheckCircle className={"ml-2"} />
                        </span>
                    )}
                </div>
            </div>

            {/* Title and Content */}
            <p className="font-semibold mt-2 text-white">{title}</p>
            <p className="text-sm text-gray-500 dark:text-gray-400">{content}</p>

            {img.length > 0 && (
                <div
                    className="w-32 h-32 mr-4 mt-2 overflow-hidden cursor-pointer"
                    onClick={() => handleImageClick(img)}
                >
                    <img src={img} alt="review_image" className="w-full h-full object-cover" />
                </div>
            )}
        </div>
    );
}

export default ReviewItem;
