import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProductContext } from "../Contexts/ProductContext";
import { ReviewContext } from "../Contexts/ReviewContext";
import ProductCard from "../Components/Product/ProductCard";

const ProductCatalogue = () => {
    const location = useLocation();
    const { products, filterProductsByCollection } = useContext(ProductContext);
    const { getReviewsByProductHandle } = useContext(ReviewContext);

    const collectionName = location.pathname.split("/")[2] || "all";
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const checkMobile = () => setIsMobile(window.innerWidth < 768);
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Set the document title to product name + ResellerHeaven
        document.title = `${collectionName.charAt(0).toUpperCase() + collectionName.slice(1)} - AppleDrops`;

        // Clean up (optional)
        return () => {
            document.title = "AppleDrops"; // Reset to default when the component unmounts
        };
    }, [collectionName]); // Depend on title to update the title when the product changes


    const calculateAverageRating = (productHandle) => {
        const reviews = getReviewsByProductHandle(productHandle);
        if (reviews.length === 0) return 0;
        const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
        return totalRating / reviews.length;
    };

    const filteredProducts =
        collectionName === "all" ? products : filterProductsByCollection(collectionName);

    // Add a safe check for filteredProducts and its collections
    const description =
        filteredProducts.length > 0 && filteredProducts[0].collections
            ? filteredProducts[0].collections[0].description
            : "No description available.";

    return (
        <div key={location.key} className="w-full">
            <div className="mt-44 md:px-0 px-4 w-full md:w-3/5 mx-auto">
                <div className="text-left items-start mb-8 px-4">
                    <h1 className="text-[#A4A4A4] text-[24px] md:text-[40px] font-semibold">
                        {collectionName === "all"
                            ? "All Products"
                            : `${collectionName.charAt(0).toUpperCase() + collectionName.slice(1)}`}
                    </h1>
                    <p className={"mt-2 text-gray-300"}>{description}</p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {filteredProducts.map((product) => {
                        const averageRating = calculateAverageRating(product.product_handle);
                        const reviews = getReviewsByProductHandle(product.product_handle);
                        const reviewsCount = reviews.length;

                        return (
                            <ProductCard
                                key={product.id}
                                product={product}
                                averageRating={averageRating}
                                reviewsCount={reviewsCount}
                                shortReview={isMobile}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ProductCatalogue;
